@mixin flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar{

    a{
        color: white;
        font-family: sans-serif;
    }
    .navbar-brand{
        font-size: 22px;
        display: flex;
        align-items: center;

        span{
            margin-left: 15px;
        }
    }

    .offcanvas {
        background-color: #444444;
        color: white;

        .nav-link{
            font-size: 20px;
        }

        @media screen and (max-width: 991px){
            .nav-item{
                border-top: solid 1px #eeeeee;
                text-align: center;
                padding: 5px;
            }
            .nav-item:nth-child(3){
                border-bottom: solid 1px #eeeeee;
                margin-bottom: 40px;
            } 
        } 
        .alignment{
            margin: 0 auto;
            @include flex-row-center;
            gap: 10px;
            img{
                width: 20px;
            }
            @media screen and (min-width: 991px) {
                display: none;
            }
        }
    }
    
    @media screen and (min-width: 992px) {
        li {
            margin-right: 30px;
        } 
    }

}

.navbar-offset{
    margin-top: 82px;
}

.footer{
    background-color: #333333;
    color: #eeeeee;
    padding: 50px;
    text-align: center;
    @include flex-row-center;
    justify-content: space-evenly;

    @media screen and (max-width: 992px) {
        flex-direction: column;
        gap: 40px;
        .main-text{
            order: 1;
        }
        .contact-info{
            order: 2;
        }
        .contact-box{
            order: 3;
        }
    }

    .contact-info{
        .alignment{

            @include flex-row-center;
            gap: 10px;
            img{
                width: 20px;
            }
        }
        
    }

    .main-text{
        font-family: sans-serif;
        h4{
            font-size: 1.8rem;
            width: max-content;
        }
        p{
            font-size: 1rem;
            max-width: 300px;
        }
    }

    .contact-box{
        font-family: sans-serif;
        @include flex-row-center;

        .email{
            margin-right: 20px;
            min-width: 200px;
            height: 40px;
            border-radius: 5px;
            border: none;
            padding: 0 10px;
            &:focus{
                outline: none;
            }
        }
        .contact-btn{
            padding: 10px 20px;
            background-color: white;
            color: #444444;
            border-radius: 5px;
            font-weight: bold;
            border: solid 2px #333333;

            &:hover{
                background-color: #444444;
                color: white;
                border: solid 2px white;
            }
        }
    }
    
}


.home-container{

    .first-section{
        height: calc(100vh - 99px);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        max-width: 40;
        padding-right: 7%;
        
        @media screen and (max-width: 1200px) {
            height: calc(80vh - 99px);
            align-items: center;
            padding: 0 10px 0 10px;
            
        }
        .text-container{
            @include flex-column-center;
            text-align: center;
            gap: 10px;
            color: #444444;
            font-family: 'Poppins', sans-serif;
            h1{
                margin-bottom: 50px;
                font-family: 'Montserrat', sans-serif;
                font-size: 4.5rem;

                @media screen and (max-width: 992px) {
                    max-width: 750px;
                }
            }
            h2{ 
                font-size: 2.8rem;
                font-weight: 400;
            }
            h4{
                font-size: 2.2rem;
            }
            @media screen and (max-width: 768px) {
                h1{
                    font-size: 3.5rem;
                }
                h2{
                    font-size: 2rem;
                }
                h4{
                    font-size: 1.8rem;
                }  
            }
        }
    }
    .second-section{
        @include flex-column-center;
        min-height: 50vh;
        background-color: #eeeeee;
        z-index: -2;
        padding: 100px 10px 100px 10px;

        .content-container{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;

            .text-container{
                text-align: center;
                color: #444444;
                @include flex-column-center;

                h2{
                    font-size: 2.8rem;
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 500;
                }
                h4{
                    margin-top: 50px;
                    text-align: center;
                    font-family: sans-serif;
                    max-width: 800px;
                    font-weight: 400;
                }
                @media screen and (max-width: 768px) {
                    h2{
                        font-size: 2rem;
                    }
                    h4{
                        font-size: 1.2rem;
                        max-width: 600px;
                    }
                }
            }
            .icons-container{
                display: flex;
                flex-direction: row;
                align-items: center;
                max-width: 500px;
                justify-content: center;
                flex-wrap: wrap;
                gap: 50px;

                .icon {
                    @include flex-column-center;
                    height: 150px;
                    width: 150px;
                    background-color: #666666;
                    position: relative;

                    img{
                        position: absolute;
                        top: calc(50% + 10px);
                        left: calc(50% - 10px);
                        transform: translate(-50%, -50%);
                        width: 75px;
                        height: 75px;
                        z-index: 3;
                    }
                    .effect-div{
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        width: 100%;
                        height: 100%;
                        background-color: white;
                        display: block;
                        border: 3px solid #666666;
                        z-index: 2;
                    }
                    @media screen and (max-width: 768px) {
                        height: 100px;
                        width: 100px;
                        img{
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
                @media screen and (max-width: 768px) {
                    max-width: 300px;
                }
                
            }
           
            @media screen and (max-width: 1380px) {
                @include flex-column-center;
                gap: 60px;
            }
        }

    }
    .section3-container{
        @include flex-column-center;
        background-color: #CCCCCC;

        .carousel {
            width:80%;
            padding: 30px 20px;

            @media screen and (max-width: 2000px) {
                width: 85%;
            }
            @media screen and (max-width: 1800px) {
                width: 90%;
            }
            @media screen and (max-width: 1650px) {
                width: 100%;
                padding: 0;
            }
            .carousel-item img{
                border-radius: 30px;

                @media screen and (max-width: 1650px) {
                    border-radius: 0;
                }
            }
            .carousel-caption {
                margin-bottom: 1.5%;
            }
          
        }

    }
    .section4-container{
        @include flex-column-center;

        background-color: #999999;
        padding:50px 20px;

        .content-container{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            flex-wrap: wrap;
            gap: 50px;

            .card{
                width: 400px;
                text-align: center;
                border-radius: 10px;
                color: #444444;
                background-color: #eeeeee;
                border: none;

                overflow: hidden;
                .card-body{
                    padding: 30px 20px;
                }

                img{
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    transition: transform 0.5s ease;
                }
                
                p {
                    margin-top: 30px;
                    font-family: sans-serif;
                    text-align: justify;
                    font-size: 1.2rem;
                }
                @media screen and (max-width: 768px) {
                    width: 300px;
                    p{
                        font-size: 1rem;
                    }
                }
            }
            .card:hover img{
                transform: scale(1.1);
            }
        }
    }
}

.about-container{

    .first-section{
        min-height: 80vh;
        @include flex-row-center;
        background-color: #eeeeee;
        justify-content: center;
        gap: 70px;
        padding: 40px;

        @media screen and (max-width: 1650px) {
            flex-direction: column;
            gap: 0;
        }

        .grid-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-gap: 20px;
            position: relative;

            img{
                transition: transform 0.5s ease;
            }

            div{
                overflow: hidden;
            }

            div:hover img{
                transform: scale(1.1);   
            }
        }
          
          
          .grid-container > div:first-child {
            grid-row: 1 / span 2;
          }
          
          .grid-container > div:nth-child(2) {
            grid-row: 1;
          }
          
          .grid-container > div:nth-child(3) {
            grid-row: 2;
          }

        .text-container{
            @include flex-column-center;
            
            max-width: 60rem;
            min-height: 500px;
            padding: 70px 50px;

            color: #444444;

            p{
                margin-top: 30px;
                font-family: sans-serif;
                font-size: 1.4rem;
                text-align: justify;
            }
            @media screen and (max-width: 800px) {
               padding: 40px 0;
                p{
                    font-size: 1.15rem;
                }
            }
        }
    }
    .second-section{
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        min-height: 50vh;
        background-attachment: fixed;

        @media screen and (max-width: 768px) {
            background-attachment: scroll;
            background-repeat: repeat;
            background-size: auto;
            background-position: center center;
        }
    }
    .section3-container{
        @include flex-column-center;
        min-height: 50vh;
        background-color: #eeeeee;
        padding: 30px;

        .content-container{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
            color:#333333;

            @media screen and (max-width: 1100px) {
                flex-direction: column;
                gap: 20px;
            }

            .text-container{
                @include flex-column-center;
                max-width: 700px;
                font-family: sans-serif;
                h3{
                    font-size: 2.5rem;
                }
                h5{
                    font-size: 1.8rem;
                }
                p{
                    font-size: 1.4rem;
                    margin-top: 25px;
                    text-align: justify;
                }
                @media screen and (max-width: 768px) {
                    h3{
                        font-size: 1.8rem;
                    }
                    h5{
                        font-size: 1.4rem;
                    }
                    p{
                        font-size: 1.15rem;
                    }
                    
                }
                
            }

            img{
                height: 300px;
                border-radius: 50%;
                border: 2px solid #666666;

                @media screen and (max-width: 768px) {
                    height: 200px;
                }
            }
        }
    }
}

.contact-container{

    .first-section{
        min-height: 60vh;
        @include flex-column-center;
        gap: 70px;
        padding: 40px;

        @media screen and (max-width: 800px) {
            gap:30px;
        }
        .header-container{
            text-align: center;

        }
       .contact-details-container{
           @include flex-row-center;
            justify-content: space-evenly;

            .v-divider{
                border-left: 2px solid #888888;
                height: 100px;
            }
            .contact-item{
                max-width: 300px;
                text-align: center;
                h6{
                    font-size: 1.4rem;
                }
                p{
                    font-size: 1.1rem;
                }
                .alignment{
                    margin-top: 10px;
                    @include flex-row-center;
                    gap: 10px;
                    img{
                        width: 20px;
                    }
                }
            }
            @media screen and (max-width: 800px) {
                @include flex-column-center;
                gap: 40px;
                padding-bottom: 30px;
                .v-divider{
                    display: none;
                }
            }
       }
    } 
    .second-section{
        min-height: 90vh;
        @include flex-row-center;
        background-color: #eeeeee;
        justify-content: center;
        padding: 40px;

        .map-container{
            @include flex-column-center;
            min-height: 50vh;
            iframe{
                width: 100%;
                height: 500px;
                max-width: 700px;
                @media screen and (max-width: 768px) {
                    height: 300px;
                }
            }
        }
        
        form{
            @include flex-column-center;
            font-family: sans-serif;
            color: #444444;

            input , textarea{
                padding: 5px 10px;
                width: 350px;
                border: solid 2px #888888;
                border-radius: 5px;
                border-image: initial;
                &:focus{
                    outline: none;
                }
            }
            textarea{
                min-height: 150px;
            }
            button{
                background-color: #444444;
                color: white;
                border: none;
                padding: 10px 20px;
                width: 350px;
                // width: 50%;
                font-size: 1.1rem;
                border-radius: 5px;
                border: 2px solid #eeeeee;
                &:hover{
                    cursor: pointer;
                    background-color: #eeeeee;
                    color: #444444;
                    border: 2px solid #444444;
                }
            }
        }

        @media screen and (max-width: 800px) {
            @include flex-column-center;
            // gap: 50px;
            padding: 50px;
        }
    }
}

